"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.StaticVideoObject = void 0;
// @ts-nocheck
var fabric_1 = require("fabric");
var StaticVideoObject = /** @class */ (function (_super) {
    __extends(StaticVideoObject, _super);
    function StaticVideoObject() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StaticVideoObject.prototype.initialize = function (video, options) {
        var defaultOpts = {
            objectCaching: false,
            cacheProperties: ["time"],
        };
        options = options || {};
        _super.prototype.initialize.call(this, video, Object.assign({}, defaultOpts, options));
        return this;
    };
    StaticVideoObject.prototype._draw = function (video, ctx, w, h) {
        var d = {
            x: -this.width / 2,
            y: -this.height / 2,
            w: this.width,
            h: this.height,
        };
        ctx.drawImage(video, d.x, d.y, d.w, d.h);
    };
    StaticVideoObject.prototype._render = function (ctx) {
        this._draw(this.getElement(), ctx);
    };
    StaticVideoObject.prototype.toObject = function (propertiesToInclude) {
        if (propertiesToInclude === void 0) { propertiesToInclude = []; }
        return fabric_1.fabric.util.object.extend(_super.prototype.toObject.call(this, propertiesToInclude), {});
    };
    StaticVideoObject.prototype.toJSON = function (propertiesToInclude) {
        if (propertiesToInclude === void 0) { propertiesToInclude = []; }
        return fabric_1.fabric.util.object.extend(_super.prototype.toObject.call(this, propertiesToInclude), {});
    };
    StaticVideoObject.type = "StaticVideo";
    return StaticVideoObject;
}(fabric_1.fabric.Image));
exports.StaticVideoObject = StaticVideoObject;
fabric_1.fabric.StaticVideo = fabric_1.fabric.util.createClass(StaticVideoObject, {
    type: StaticVideoObject.type,
});
