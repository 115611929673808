"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StaticTextObject = void 0;
var fabric_1 = require("fabric");
var StaticTextObject = /** @class */ (function (_super) {
    __extends(StaticTextObject, _super);
    function StaticTextObject() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StaticTextObject.prototype.initialize = function (options) {
        var text = options.text, textOptions = __rest(options
        //@ts-ignore
        , ["text"]);
        //@ts-ignore
        _super.prototype.initialize.call(this, text, __assign({}, textOptions));
        return this;
    };
    StaticTextObject.prototype.toObject = function (propertiesToInclude) {
        if (propertiesToInclude === void 0) { propertiesToInclude = []; }
        // const originalText = this.getText()
        return fabric_1.fabric.util.object.extend(_super.prototype.toObject.call(this, propertiesToInclude), {
            fontURL: this.fontURL,
            // keys: this.keys,
            // originalText: originalText,
            // metadata: this.metadata,
        });
    };
    StaticTextObject.prototype.toJSON = function (propertiesToInclude) {
        if (propertiesToInclude === void 0) { propertiesToInclude = []; }
        // const originalText = this.getText()
        return fabric_1.fabric.util.object.extend(_super.prototype.toObject.call(this, propertiesToInclude), {
            fontURL: this.fontURL,
            // keys: this.keys,
            // originalText: originalText,
            // metadata: this.metadata,
        });
    };
    StaticTextObject.fromObject = function (options, callback) {
        return callback && callback(new fabric_1.fabric.StaticText(options));
    };
    StaticTextObject.type = "StaticText";
    return StaticTextObject;
}(fabric_1.fabric.Textbox));
exports.StaticTextObject = StaticTextObject;
fabric_1.fabric.StaticText = fabric_1.fabric.util.createClass(StaticTextObject, {
    type: StaticTextObject.type,
});
fabric_1.fabric.StaticText.fromObject = StaticTextObject.fromObject;
