"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StaticAudioObject = void 0;
var fabric_1 = require("fabric");
var StaticAudioObject = /** @class */ (function (_super) {
    __extends(StaticAudioObject, _super);
    function StaticAudioObject() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StaticAudioObject.prototype.initialize = function (options) {
        _super.prototype.initialize.call(this, __assign({ width: 0, height: 0, selectable: false, evented: false, visible: false }, options));
        return this;
    };
    StaticAudioObject.fromObject = function (options, callback) {
        return callback && callback(new fabric_1.fabric.StaticAudio(options));
    };
    StaticAudioObject.type = "StaticAudio";
    return StaticAudioObject;
}(fabric_1.fabric.Object));
exports.StaticAudioObject = StaticAudioObject;
fabric_1.fabric.StaticAudio = fabric_1.fabric.util.createClass(StaticAudioObject, {
    type: StaticAudioObject.type,
});
fabric_1.fabric.StaticAudio.fromObject = StaticAudioObject.fromObject;
