"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StaticPathObject = void 0;
var fabric_1 = require("fabric");
var StaticPathObject = /** @class */ (function (_super) {
    __extends(StaticPathObject, _super);
    function StaticPathObject() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StaticPathObject.prototype.initialize = function (options) {
        var path = options.path, pathOptions = __rest(options
        //@ts-ignore
        , ["path"]);
        //@ts-ignore
        _super.prototype.initialize.call(this, path, pathOptions);
        return this;
    };
    StaticPathObject.prototype.toObject = function (propertiesToInclude) {
        if (propertiesToInclude === void 0) { propertiesToInclude = []; }
        return _super.prototype.toObject.call(this, propertiesToInclude);
    };
    StaticPathObject.prototype.toJSON = function (propertiesToInclude) {
        if (propertiesToInclude === void 0) { propertiesToInclude = []; }
        return _super.prototype.toObject.call(this, propertiesToInclude);
    };
    StaticPathObject.fromObject = function (options, callback) {
        return callback && callback(new fabric_1.fabric.StaticPath(options));
    };
    StaticPathObject.type = "StaticPath";
    return StaticPathObject;
}(fabric_1.fabric.Path));
exports.StaticPathObject = StaticPathObject;
fabric_1.fabric.StaticPath = fabric_1.fabric.util.createClass(StaticPathObject, {
    type: StaticPathObject.type,
});
fabric_1.fabric.StaticPath.fromObject = StaticPathObject.fromObject;
